import React, { useState, useEffect } from "react"
import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import SocialMenu from "../components/socialmenu"
import { List } from 'react-content-loader'
import { Formik, Form, ErrorMessage } from 'formik';
import ky from 'ky';

const MailPage = location => {
  const [token, setToken] = useState({ready: true});
  useEffect(() => {
    ky.get(`https://api.wirehead.fm/mailsend/email.cgi`, {
      credentials: 'include'
    }).json()
      .then(resultData => {
        setToken({ready:true, token:resultData.token})
      })
  }, []);
  if (token.error) {
    return (<Layout>
      <SEO title="Mail" pathname="/mail" />
      <TwoCol
        left={
          <div className="content">
            <h1>Mail</h1>
            <article className="message is-danger">
              <div className="message-header">
                <p>Error sending message</p>
              </div>
              <div className="message-body">
                Your email has not been sent because an error occurred
              </div>
            </article>
          </div>
        }
        right={
          <aside className="menu">
            <SocialMenu />
          </aside>          
        }
      />
    </Layout>)
  } else if (token.done) {
    return (<Layout>
      <SEO title="Mail" pathname="/mail" />
      <TwoCol
        left={
          <div className="content">
            <h1>Mail</h1>
            <article className="message is-success">
              <div className="message-header">
                <p>Message sent</p>
              </div>
              <div className="message-body">
                Your email has been sent
              </div>
            </article>
          </div>
        }
        right={
          <aside className="menu">
            <SocialMenu />
          </aside>          
        }
      />
    </Layout>)
  } else if (token.ready) {
    return (
      <Layout>
        <SEO title="Mail" pathname="/mail" />
        <TwoCol
          left={
            <div className="content">
              <h1>Mail</h1>
              <Formik
                initialValues={{ name: '', email: '', comment: ''}}
                validate={values => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'An email is required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  if (!values.comment) {
                    errors.comment = 'Some text in the message is required';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  values.token = token.token
                  ky.post('https://api.wirehead.fm/mailsend/email.cgi', {
                    credentials: 'include',
                    json: values,
                  }).json()
                  .then((data) => {
                    console.log('Success:', data);
                    setToken({done:true})
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    console.error('Error:', error);
                    setToken({error:true})
                    setSubmitting(false);
                  });
                }}
              >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
              <Form>
                <div className="field">
                  <label htmlFor="name" className="label">
                    Name:
                  </label>
                  <div className="control">
                    <input
                      name="name"
                      className={(errors.name && touched.name) ? "input is-danger" : "input"}
                      type="text"
                      placeholder="Name input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    ></input>
                  </div>
                  <p className={(errors.name && touched.name) ? "help is-danger" : "help"}><ErrorMessage name="name" /></p>
                </div>
                <div className="field">
                  <label htmlFor="email" className="label">
                    Email address:
                  </label>
                  <div className="control">
                    <input
                      name="email"
                      className={(errors.email && touched.email) ? "input is-danger" : "input"}
                      type="email"
                      placeholder="Email input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    ></input>
                  </div>
                  <p className={(errors.email && touched.email) ? "help is-danger" : "help"}><ErrorMessage name="email" /></p>
                </div>
                <div className="field">
                  <label htmlFor="comment" className="label">
                    Message
                  </label>
                  <div className="control">
                    <textarea
                      name="comment"
                      rows="15"
                      className={(errors.comment && touched.comment) ? "textarea is-danger" : "textarea"}
                      placeholder="Message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment}
                    ></textarea>
                  </div>
                  <p className={(errors.comment && touched.comment) ? "help is-danger" : "help"}><ErrorMessage name="comment" /></p>
                </div>
                <div className="field">
                  <div className="control">
                    <button 
                      type="submit" 
                      className={isSubmitting ? "button is-loading is-primary" : "button is-primary"}
                      disabled={isSubmitting} >Submit</button>
                  </div>
                </div>
              </Form>
              )}
              </Formik>
            </div>
          }
          right={
            <aside className="menu">
              <SocialMenu />
            </aside>
          }
        />
        
      </Layout>
    )
  } else {
    return (<Layout>
      <SEO title="Mail" pathname="/mail" />
      <div className="content">
        <h1>Mail</h1>
        <List />
      </div>
    </Layout>)
  }
}

export default MailPage

export const frontmatter = {
  title: "Mail",
}